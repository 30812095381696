import React from 'react'
import { Doughnut } from '@reactchartjs/react-chart.js'

function DoughnutChart(props) {
  {
    const possibleColors = [
      '#ee9595',
      'rgba(255, 87, 51, 1)',
      '#0779e4',
      '#970747',
      '#19456b',
      'rgba(223, 255, 0, 1)',
      '#16c79a',
      '#cdac81',
      '#ce2525',
      '#c7753d',
      '#f6d887',
      '#fdb827',
      '#ecb390',
      'rgba(46, 204, 113, 1)',
      'rgba(125, 60, 152, 1)',
      '#db2d43',
      '#cd5d7d',
      '#21209c',
      '#fa26a0',
      'rgba(247, 220, 111, 1)',
      'rgba(41, 128, 185, 1)',
      '#f5587b',
      '#794c74',
      '#9d0191',
    ]

    console.log(props.donutData)

    var labels = []
    var datas = []
    var backgroundColors = []

    var options = {
      maintainAspectRatio: true,
      responsive: true,
      legend: {
        position: 'bottom',
        display: false,
        labels: {
          boxWidth: 10,
        },
      },
    }

    if (!!props.donutData) {
      var dData = props.donutData

      var tempBackgroundColors = []
      while (tempBackgroundColors.length < dData.length + 1) {
        var r = Math.floor(Math.random() * 23) + 1
        if (tempBackgroundColors.indexOf(r) === -1) {
          tempBackgroundColors.push(r)
          backgroundColors.push(possibleColors[r])
        }
        console.log(tempBackgroundColors)
      }

      dData.forEach((element) => {
        console.log(element)
        datas.push(element.fees.sum)
        labels.push(element.pair.token0.symbol + '-' + element.pair.token1.symbol)
      })

      var data = {
        labels: labels,
        datasets: [
          {
            label: 'Liquidity distribution',
            data: datas,
            backgroundColor: backgroundColors,
            borderWidth: 0,
          },
        ],
      }
    }
  }
  return (
    <>
      <Doughnut data={data} options={options} />
    </>
  )
}

export default DoughnutChart
