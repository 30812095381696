import React, { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { useUserTransactions, useUserPositions } from '../contexts/User'
import TxnList from '../components/TxnList'
import Panel from '../components/Panel'
import { formattedNum } from '../utils'
import Row, { AutoRow, RowFixed, RowBetween } from '../components/Row'
import { AutoColumn } from '../components/Column'
import UserChart from '../components/UserChart'
import PairReturnsChart from '../components/PairReturnsChart'
import PositionList from '../components/PositionList'
import { TYPE } from '../Theme'
import { ButtonDropdown, ButtonLight, ButtonDark } from '../components/ButtonStyled'
import { PageWrapper, ContentWrapper, StyledIcon } from '../components'
import DoubleTokenLogo from '../components/DoubleLogo'
import { Bookmark, Activity } from 'react-feather'
import Link from '../components/Link'
import { FEE_WARNING_TOKENS } from '../constants'
import { BasicLink } from '../components/Link'
import { useMedia } from 'react-use'
import Search from '../components/Search'
import CustomActiveShapePieChart from '../components/CustomActiveShapePieChart'
import PairChart from '../components/PairChart'
const AccountWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  padding: 6px 16px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Header = styled.div``

const DashboardWrapper = styled.div`
  width: 100%;
`

const DropdownWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  /* border: 1px solid #edeef2; */
  border-radius: 12px;
`

const Flyout = styled.div`
  position: absolute;
  top: 38px;
  left: -1px;
  width: 100%;
  background-color: ${({ theme }) => theme.bg1};
  z-index: 999;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 4px;
  /* border: 1px solid #edeef2; */
  border-top: none;
`

const MenuRow = styled(Row)`
  width: 100%;
  padding: 12px 0;
  padding-left: 12px;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.bg2};
  }
`

const PanelWrapper = styled.div`
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: max-content;
  gap: 2px;
  display: inline-grid;
  width: 100%;
  align-items: start;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    align-items: stretch;
    > * {
      grid-column: 1 / 4;
    }

    > * {
      &:first-child {
        width: 100%;
      }
    }
  }
`

const Warning = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text1};
  padding: 1rem;
  font-weight: 600;
  border-radius: 10px;
  margin-bottom: 1rem;
  width: calc(100% - 2rem);
`

function AccountPage({ account }) {
  // get data for this account
  const transactions = useUserTransactions(account)
  const positions = useUserPositions(account)

  // get data for user stats
  const transactionCount = transactions?.swaps?.length + transactions?.burns?.length + transactions?.mints?.length

  // get derived totals
  let totalSwappedUSD = useMemo(() => {
    return transactions?.swaps
      ? transactions?.swaps.reduce((total, swap) => {
          return total + parseFloat(swap.amountUSD)
        }, 0)
      : 0
  }, [transactions])

  // if any position has token from fee warning list, show warning
  const [showWarning, setShowWarning] = useState(false)
  useEffect(() => {
    if (positions) {
      for (let i = 0; i < positions.length; i++) {
        if (
          FEE_WARNING_TOKENS.includes(positions[i].pair.token0.id) ||
          FEE_WARNING_TOKENS.includes(positions[i].pair.token1.id)
        ) {
          setShowWarning(true)
        }
      }
    }
  }, [positions])

  // settings for list view and dropdowns
  const hideLPContent = positions && positions.length === 0
  const [showDropdown, setShowDropdown] = useState(false)
  const [activePosition, setActivePosition] = useState()

  const dynamicPositions = activePosition ? [activePosition] : positions

  const aggregateFees = dynamicPositions?.reduce(function (total, position) {
    return total + position.fees.sum
  }, 0)

  const positionValue = useMemo(() => {
    return dynamicPositions
      ? dynamicPositions.reduce((total, position) => {
          return (
            total +
            (parseFloat(position?.liquidityTokenBalance) / parseFloat(position?.pair?.totalSupply)) *
              position?.pair?.reserveUSD
          )
        }, 0)
      : null
  }, [dynamicPositions])

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }, [])

  const below600 = useMedia('(max-width: 600px)')

  return (
    <PageWrapper>
      <ContentWrapper>
        <RowBetween>
          <TYPE.body>
            <BasicLink to="/accounts">{'Accounts '}</BasicLink>→{' '}
            <Link lineHeight={'145.23%'} href={'https://etherscan.io/address/' + account} target="_blank">
              {' '}
              {account?.slice(0, 42)}{' '}
            </Link>
          </TYPE.body>
          {!below600 && <Search small={true} />}
        </RowBetween>
        <Header>
          <RowBetween>
            <span>
              <TYPE.header fontSize={24}>{account?.slice(0, 6) + '...' + account?.slice(38, 42)}</TYPE.header>
              <Link lineHeight={'145.23%'} href={'https://etherscan.io/address/' + account} target="_blank">
                <TYPE.main fontSize={14}>View on Etherscan</TYPE.main>
              </Link>
            </span>
            <AccountWrapper>
              <StyledIcon>
                <Bookmark style={{ opacity: 0.4 }} />
              </StyledIcon>
            </AccountWrapper>
          </RowBetween>
        </Header>
        <DashboardWrapper>
          <PanelWrapper>
            <Panel style={{ borderRadius: '12px', height: '100%', marginBottom: '1rem' }}>
              <RowBetween>
                <Panel style={{ borderRadius: '12px', backgroundColor: '#3d73f7' }}>
                  <AutoRow gap="20px">
                    <AutoColumn gap="10px">
                      <TYPE.main>Liquidity (Including Fees)</TYPE.main>
                      <div />
                      <TYPE.header fontSize={'24px'}>
                        {positionValue
                          ? formattedNum(positionValue, true)
                          : positionValue === 0
                          ? formattedNum(0, true)
                          : '-'}
                      </TYPE.header>
                    </AutoColumn>
                  </AutoRow>
                </Panel>
                <Panel style={{ borderRadius: '12px', backgroundColor: '#52d891', marginLeft: '10px' }}>
                  <AutoRow gap="20px">
                    <AutoColumn gap="10px">
                      <TYPE.main>Fees Earned (Cumulative)</TYPE.main>
                      <div />
                      <TYPE.header fontSize={'24px'}>
                        {aggregateFees ? formattedNum(aggregateFees, true, true) : '-'}
                      </TYPE.header>
                    </AutoColumn>
                  </AutoRow>
                </Panel>
              </RowBetween>
            </Panel>
            <Panel style={{ borderRadius: '12px', height: '100%', marginBottom: '1rem' }}>
              <RowBetween>
                <Panel style={{ borderRadius: '12px', backgroundColor: '#a24aaa' }}>
                  <AutoRow gap="20px">
                    <AutoColumn gap="12px">
                      <TYPE.main>Total Swapped</TYPE.main>
                      <TYPE.header fontSize={24}>
                        {totalSwappedUSD ? formattedNum(totalSwappedUSD, true) : '-'}
                      </TYPE.header>
                    </AutoColumn>
                  </AutoRow>
                </Panel>
                <Panel style={{ borderRadius: '12px', backgroundColor: '#ce2525', marginLeft: '10px' }}>
                  <AutoRow gap="20px">
                    <AutoColumn gap="12px">
                      <TYPE.main>Fees Paid</TYPE.main>
                      <TYPE.header fontSize={24}>
                        {totalSwappedUSD ? formattedNum(totalSwappedUSD * 0.003, true).slice(0, 6) : '-'}
                      </TYPE.header>
                    </AutoColumn>
                  </AutoRow>
                </Panel>
                <Panel style={{ borderRadius: '12px', backgroundColor: '#fa26a0', marginLeft: '10px' }}>
                  <AutoRow gap="20px">
                    <AutoColumn gap="12px">
                      <TYPE.main>Total Transactions</TYPE.main>
                      <TYPE.header fontSize={24}>{transactionCount ? transactionCount : '-'}</TYPE.header>
                    </AutoColumn>
                  </AutoRow>
                </Panel>
              </RowBetween>
            </Panel>

            {/* Pie chart panel starts */}
            <Panel style={{ borderRadius: '12px' }}>
              <CustomActiveShapePieChart style={{ maxWidth: '400px !important' }} donutData={positions} />
              <RowBetween>
                <a href="https://exchange.trams.io/#/swap">
                  <ButtonLight style={{}}> Swap 💰</ButtonLight>
                </a>
                <a href="https://exchange.trams.io/#/pool">
                  <ButtonDark style={{}}>Add Liquidity 📥</ButtonDark>
                </a>
              </RowBetween>
            </Panel>
            {/* Pie chart panel ends */}

            {/* Liquidity panel starts */}
            <Panel style={{ borderRadius: '12px', height: '100%', marginBottom: '1rem' }}>
              <DropdownWrapper>
                <ButtonDropdown width="100%" onClick={() => setShowDropdown(!showDropdown)} open={showDropdown}>
                  {!activePosition && (
                    <RowFixed>
                      <StyledIcon>
                        <Activity size={16} />
                      </StyledIcon>
                      <TYPE.body ml={'10px'}>All Positions</TYPE.body>
                    </RowFixed>
                  )}
                  {activePosition && (
                    <RowFixed>
                      <DoubleTokenLogo
                        a0={activePosition.pair.token0.id}
                        a1={activePosition.pair.token1.id}
                        size={16}
                      />
                      <TYPE.body ml={'16px'}>
                        {activePosition.pair.token0.symbol}-{activePosition.pair.token1.symbol} Position
                      </TYPE.body>
                    </RowFixed>
                  )}
                </ButtonDropdown>
                {showDropdown && (
                  <Flyout>
                    <AutoColumn gap="0px">
                      {positions?.map((p, i) => {
                        if (p.pair.token1.symbol === 'WETH') {
                          p.pair.token1.symbol = 'ETH'
                        }
                        if (p.pair.token0.symbol === 'WETH') {
                          p.pair.token0.symbol = 'ETH'
                        }
                        return (
                          p.pair.id !== activePosition?.pair.id && (
                            <MenuRow
                              onClick={() => {
                                setActivePosition(p)
                                setShowDropdown(false)
                              }}
                              key={i}
                            >
                              <DoubleTokenLogo a0={p.pair.token0.id} a1={p.pair.token1.id} size={16} />
                              <TYPE.body ml={'16px'}>
                                {p.pair.token0.symbol}-{p.pair.token1.symbol} Position
                              </TYPE.body>
                            </MenuRow>
                          )
                        )
                      })}
                      {activePosition && (
                        <MenuRow
                          onClick={() => {
                            setActivePosition()
                            setShowDropdown(false)
                          }}
                        >
                          <RowFixed>
                            <StyledIcon>
                              <Activity size={16} />
                            </StyledIcon>
                            <TYPE.body ml={'10px'}>All Positions</TYPE.body>
                          </RowFixed>
                        </MenuRow>
                      )}
                    </AutoColumn>
                  </Flyout>
                )}
              </DropdownWrapper>
              {activePosition ? <PairReturnsChart account={account} position={activePosition} /> : <UserChart />}
            </Panel>
            {/* Liquidity panel ends */}

            {/* Position List panel starts */}
            <Panel style={{ marginTop: '0rem', borderRadius: '12px' }}>
              <PositionList positions={positions} />
            </Panel>
            {/* Position List panel ends */}

            {/* User chart panel starts */}
            <Panel style={{ borderRadius: '12px', width: '101%', marginTop: '0rem', gridColumn: '2', gridRow: '2' }}>
              {activePosition ? (
                <UserChart account={account} position={activePosition} />
              ) : (
                <UserChart account={account} position={activePosition} />
              )}
            </Panel>
            {/* User chart panel starts */}
          </PanelWrapper>
          <TYPE.main fontSize={'1.125rem'} style={{ marginTop: '3rem' }}>
            Transactions
          </TYPE.main>{' '}
          <Panel
            style={{
              marginTop: '1.5rem',
            }}
          >
            <TxnList transactions={transactions} />
          </Panel>
        </DashboardWrapper>
      </ContentWrapper>
    </PageWrapper>
  )
}

export default AccountPage
